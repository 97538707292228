import { useEffect, type FC, useState } from 'react'
import { FacebookIcon, InstagramIcon, PhoneIcon, TelegramIcon, ViberIcon } from 'assets'
import styles from './Header.module.scss'
import { useAppSelector } from 'hooks/useTypedSelector'

const Footer: FC = () => {
  const { settingSlice } = useAppSelector(state => state)
  function extractUsernameFromTelegramAccount(telegramAccount: string) {
    if (!telegramAccount) {
      return null
    }
    let cleanedAccount = telegramAccount.replace(/^https?:\/\/t\.me\//, '')

    cleanedAccount = cleanedAccount.replace(/^@/, '')

    return cleanedAccount
  }
  function generateViberLink(viberInfo: any) {
    if (!viberInfo) {
      return null
    }

    if (viberInfo.startsWith('http') || viberInfo.startsWith('viber')) {
      return (
        <a href={viberInfo} target='_blank' style={{ display: 'flex', alignItems: 'center' }}>
          <ViberIcon className='w-5' />
        </a>
      )
    }

    if (/^\+\d{2,3}/.test(viberInfo)) {
      return (
        <a
          href={`viber://contact?number=${encodeURIComponent(viberInfo)}`}
          target='_blank'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <ViberIcon className='w-5' />
        </a>
      )
    }

    return null
  }

  const phone_international = localStorage.getItem('phone_international')

  const [showPhone, setShowPhone] = useState(false)

  useEffect(() => {
    if(phone_international){
      setShowPhone(true)
    } else
    {
      setShowPhone(false)
    }
    if(settingSlice.data?.data?.phone.international) {
      setShowPhone(true)
      localStorage.setItem('phone_international' ,settingSlice.data?.data?.phone.international )
    }
  }, [settingSlice.data?.data?.phone.international])

  return (
    <footer className={styles.wrapper}>
      <div className={styles.wrapper__container}>
        <div className='hidden sm:flex' />
        <div className={styles.wrapper__social}>
          <div className={styles.wrapper__social__icons}>
            {settingSlice.data?.data?.telegram_url && (
              <a
                href={`tg://resolve?domain=${extractUsernameFromTelegramAccount(settingSlice.data.data?.telegram_url)}`}
                target='__blank'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <TelegramIcon className='w-5' />
              </a>
            )}
            {generateViberLink(settingSlice.data.data?.viber_url)}

            {settingSlice.data.data?.instagram_url && (
              <a
                href={settingSlice.data.data.instagram_url}
                target='__blank'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <InstagramIcon className='w-5' />
              </a>
            )}
            {settingSlice.data.data?.facebook_url && (
              <a
                href={settingSlice.data.data.facebook_url}
                target='__blank'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <FacebookIcon className='w-5' />
              </a>
            )}
          </div>
          {showPhone && (
            <a href={`tel:${settingSlice.data?.data?.phone?.mobile}`} style={{ textDecoration: 'none' }}>
              <div className={styles.wrapper__social__phone}>
                <PhoneIcon fill='white' />

                <p className={styles.wrapper__social__phone__number}>
                  {phone_international || settingSlice.data?.data?.phone.international}
                </p>
              </div>
            </a>
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
